import { API, Auth } from "aws-amplify";

export const listOfUsers = async (jwtToken: string, limit: number = 10): Promise<any> => {
    try {
        let apiName = 'AdminQueries';
        let path = '/listUsers';
        let options = {
            queryStringParameters: {
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: jwtToken
            }
        };
        const response = await API.get(apiName, path, options);
        return response;
    } catch (error) {
        console.log('Error retrieving listOfUsers:', error);
        return null;
    }
};

export const createUser = async (jwtToken: string, username: string, email: string): Promise<any> => {
    try {
        let apiName = 'AdminQueries';
        let path = '/createUser';
        let options = {
            body: {
                username: username,
                email: email
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
            }
        };
        const response = await API.post(apiName, path, options);
        
        return response;
    } catch (error) {
        console.log('User already exists, enabling user', error);
        return enableUser(jwtToken, email);
    }
};

export const resendUserEmail = async (jwtToken: string, username: string, email: string): Promise<any> => {
    try {
        let apiName = 'AdminQueries';
        let path = '/resendUserEmail';
        let options = {
            body: {
                username: username,
                email: email
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
            }
        };
        const response = await API.post(apiName, path, options);
        console.log('re-sended email to user: ' + email);
        return response;
    } catch (error) {
       console.log('Error retrieving resendUserEmail:', error);
        return null;
    }
};


export const disableUser = async (jwtToken: string, username: string): Promise<any> => {
    try {

        //TODO Borrar el usuario en cascada, empezando por graphql
        let apiName = 'AdminQueries';
        let path = '/disableUser';
        let options = {
            body: {
                username: username
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
            }
        };
        const response = await API.post(apiName, path, options);
        return response;
    } catch (error) {
        console.log('Error retrieving disableUser:', error);
        return null;
    }
};

export const enableUser = async (jwtToken: string, username: string): Promise<any> => {
    try {
        let apiName = 'AdminQueries';
        let path = '/enableUser';
        let options = {
            body: {
                username: username
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': jwtToken
            }
        };
        const response = await API.post(apiName, path, options);
        return response;
    } catch (error) {
        console.log('Error retrieving enableUser:', error);
        return null;
    }
};