// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { ClassType, Class, User, UserCanceledClass, UserJoinedClass, CanceledClass, CancelledDay, ClassUsers } = initSchema(schema);

export {
  ClassType,
  Class,
  User,
  UserCanceledClass,
  UserJoinedClass,
  CanceledClass,
  CancelledDay,
  ClassUsers
};