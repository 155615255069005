import React, { useContext, useState, useEffect } from 'react';
import { View, SafeAreaView, StyleSheet, TouchableOpacity, Modal, Text } from 'react-native';
import AddButton from './components/AddButton';
import BasicTable from './components/BasicTable';
import { Class } from '../../../models';
import { UserExtended } from '../../../types';
import { getAvailableClasses } from '../../../services/goulapi/goulapi.class';
import { getUsersAndInfo } from '../../../services/goulapi/goulapi.user';
import { createUser } from '../../../services/cognito/cognito.service';
import { Auth } from 'aws-amplify';
import { LoadingContext } from '../../../components/LoadingContext';
import UserCalendarNavigator from '../../user/userCalendar/UserCalendarNavigator';



function AdminUserList() {
  const { isLoading, setIsLoading, whoAmI } = useContext(LoadingContext);
  const [toReload, setToReload] = useState(false);
  const [userList, setUserList] = useState<UserExtended[]>([]);
  const [classList, setClassList] = useState<Class[]>([]);
  const [token, setToken] = useState('');
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const impostUser = () => {
    setIsModalVisible(true);
  };

  async function setTableValues() {
    await setUserList(await getUsersAndInfo());
    await setClassList(await getAvailableClasses());
    await setIsLoading(false);
    await setToReload(false);
  }

  async function firstLoad() {
    await setToken((await Auth.currentSession()).getAccessToken().getJwtToken());
    await setIsFirstTime(false);
    await setToReload(true);
  }

  useEffect(() => {
    if (isFirstTime) {
      setIsLoading(true);
      firstLoad();
    }
  }, [isFirstTime]);

  useEffect(() => {
    if (!isFirstTime && toReload) {
      setIsLoading(true);
      setTableValues();
    }
  }, [toReload]);


  return (
    <SafeAreaView style={styles.container}>
      {toReload ? null :
        <>
          <View style={styles.buttonView}>
            <AddButton buttonText='+ Añadir' onSave={(email: string, name: string) => { createUser(token, name, email).then(() => setToReload(true)); }}></AddButton>
          </View><View style={styles.tableView}>
            <BasicTable rowNames={['NOMBRE', 'E-MAIL', 'TELEFONO', 'CLASES', '']} setToReload={setToReload} userList={userList} classList={classList} impostUser={impostUser} />
          </View>
        </>
      }
    <Modal visible={isModalVisible} transparent={true}>
      <View style={styles.modalView}>
        <TouchableOpacity
          style={[styles.closeButton, { position: 'absolute', top: 10, right: 10 }]}
          onPress={() => setIsModalVisible(false)}
        >
          <Text style={styles.closeButtonText}>Close</Text>
        </TouchableOpacity>
        <Text style={styles.modalText}>Name: {whoAmI.name}</Text>
        <Text style={styles.modalText}>Email: {whoAmI.email}</Text>
      </View>
      <UserCalendarNavigator />
    </Modal>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFF',
    padding: 20,
    margin: 5,
    minWidth: 1200
  },
  buttonView: {
    marginTop: 10,
    marginBottom: 2,
    margin: 10,
    alignItems: 'flex-end',
  },
  tableView: {
    marginTop: 2,
    marginBottom: 10,
    margin: 10
  },
  loadingModal: {
    width: '80%',
    maxHeight: '80%',
    padding: 20,
    borderRadius: 10,
    overflow: 'hidden',
    alignContent: 'center'
  },
  modalView: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  closeButton: {
    position: 'absolute',
    top: 20,
    right: 20,
    backgroundColor: 'transparent',
    zIndex: 1,
  },
  closeButtonText: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalText: {
    color: 'black',
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  userInfo: {
    flexDirection: 'row',
    marginBottom: 10,
  }
});


export default AdminUserList;