/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getClassType = /* GraphQL */ `
  query GetClassType($id: ID!) {
    getClassType(id: $id) {
      id
      name
      description
      color
      classes {
        items {
          id
          classTypeID
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClassTypes = /* GraphQL */ `
  query ListClassTypes(
    $filter: ModelClassTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        color
        classes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClass = /* GraphQL */ `
  query GetClass($id: ID!) {
    getClass(id: $id) {
      id
      classTypeID
      classType {
        id
        name
        description
        color
        classes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      description
      time
      duration
      dayOfWeek
      maxAssist
      users {
        items {
          id
          classId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      canceledClasses {
        items {
          id
          date
          description
          classID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClasses = /* GraphQL */ `
  query ListClasses(
    $filter: ModelClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClasses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        classTypeID
        classType {
          id
          name
          description
          color
          createdAt
          updatedAt
          __typename
        }
        users {
          items {
            userId
          }
        }
        description
        time
        duration
        dayOfWeek
        maxAssist
        users {
          nextToken
          __typename
        }
        canceledClasses {
          items {
            id
            date
            description
            classID
          }
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const classesByClassTypeID = /* GraphQL */ `
  query ClassesByClassTypeID(
    $classTypeID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classesByClassTypeID(
      classTypeID: $classTypeID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classTypeID
        classType {
          id
          name
          description
          color
          createdAt
          updatedAt
          __typename
        }
        description
        time
        duration
        dayOfWeek
        maxAssist
        users {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      classes {
        items{
          class {
          id
          classTypeID
          classType {
            name
            description
            color
          }
          description
          time
          duration
          dayOfWeek
          maxAssist
      canceledClasses {
        items {
          id
          date
              description
          classID
          }
          }
          }
        }
      }
      canceledClasses {
        items{
          class {
            classType {
              name
              description
              color
            }
            description
            time
            duration
            dayOfWeek
          }
          date
        }
      }
      joinedClasses {
        items {
          class {
            classType {
              name
              description
              color
            }
            description
            time
            duration
            dayOfWeek
          }
          date
        }
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        classes {
          items{
            id
            class {
              id
              classType {
                name
                description
                color
              }
              description
              time
              duration
              dayOfWeek
              maxAssist
            }
          }
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserCanceledClass = /* GraphQL */ `
  query GetUserCanceledClass($id: ID!) {
    getUserCanceledClass(id: $id) {
      id
      userJoinedClass(filter: {isCancelled: {ne: true}}) {
        items {
          id
          userCanceledClassID
          userID
          classID
          date
          owner
          isCancelled
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      userID
      user {
        id
        classes {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        joinedClasses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      classID
      class {
        id
        classTypeID
        classType {
          id
          name
          description
          color
          createdAt
          updatedAt
          __typename
        }
        description
        time
        duration
        dayOfWeek
        maxAssist
        users {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      date
      owner
      isCancelled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserCanceledClasses = /* GraphQL */ `
  query ListUserCanceledClasses(
    $id: ID
    $filter: ModelUserCanceledClassFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserCanceledClasses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        date
        owner
        isCancelled
        userJoinedClass(filter: {isCancelled: {ne: true}}) {
          items {
            id
            userCanceledClassID
            userID
            classID
            class {
              id
              classTypeID
              description
              time
              dayOfWeek
            }
            date
            owner
            createdAt
            updatedAt
            __typename
          }
        }
        userID
        user {
          id
          createdAt
          updatedAt
          __typename
        }
        classID
        class {
          id
          classTypeID
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userCanceledClassesByUserIDAndDate = /* GraphQL */ `
  query UserCanceledClassesByUserIDAndDate(
    $userID: ID!
    $date: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserCanceledClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userCanceledClassesByUserIDAndDate(
      userID: $userID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userJoinedClass(filter: {isCancelled: {ne: true}}) {
          items {
            id
            userCanceledClassID
            userID
            classID
            class {
              id
              classTypeID
              description
              time
              dayOfWeek
            }
            date
            owner
            createdAt
            updatedAt
            __typename
          }
        }
        userID
        user {
          id
          createdAt
          updatedAt
          __typename
        }
        classID
        class {
          id
          classTypeID
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        date
        owner
        isCancelled
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userCanceledClassesByClassIDAndDate = /* GraphQL */ `
  query UserCanceledClassesByClassIDAndDate(
    $classID: ID!
    $date: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserCanceledClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userCanceledClassesByClassIDAndDate(
      classID: $classID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userJoinedClass {
          nextToken
          __typename
        }
        userID
        user {
          id
          createdAt
          updatedAt
          __typename
        }
        classID
        class {
          id
          classTypeID
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        date
        owner
        isCancelled
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userCanceledClassesByDateAndId = /* GraphQL */ `
  query UserCanceledClassesByDateAndId(
    $date: Int!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserCanceledClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userCanceledClassesByDateAndId(
      date: $date
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userJoinedClass {
          nextToken
          __typename
        }
        userID
        user {
          id
          createdAt
          updatedAt
          __typename
        }
        classID
        class {
          id
          classTypeID
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        date
        owner
        isCancelled
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserJoinedClass = /* GraphQL */ `
  query GetUserJoinedClass($id: ID!) {
    getUserJoinedClass(id: $id) {
      id
      userCanceledClassID
      userCanceledClass(filter: {isCancelled: {ne: true}}) {
        id
        userJoinedClass {
          nextToken
          __typename
        }
        userID
        user {
          id
          createdAt
          updatedAt
          __typename
        }
        classID
        class {
          id
          classTypeID
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        date
        owner
        isCancelled
        createdAt
        updatedAt
        __typename
      }
      userID
      user {
        id
        classes {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        joinedClasses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      classID
      class {
        id
        classTypeID
        classType {
          id
          name
          description
          color
          createdAt
          updatedAt
          __typename
        }
        description
        time
        duration
        dayOfWeek
        maxAssist
        users {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      date
      owner
      isCancelled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUserJoinedClasses = /* GraphQL */ `
  query ListUserJoinedClasses(
    $id: ID
    $filter: ModelUserJoinedClassFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserJoinedClasses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userCanceledClassID
        userCanceledClass {
          id
          userID
          classID
          class {
            id
            classTypeID
            description
            time
            dayOfWeek
          }
          date
          owner
          isCancelled
          createdAt
          updatedAt
          __typename
        }
        userID
        user {
          id
          createdAt
          updatedAt
          __typename
        }
        classID
        class {
          id
          classTypeID
          classType {
            name
            description
            color
          }
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        date
        owner
        isCancelled
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const userJoinedClassesByClassIDAndDate = /* GraphQL */ `
  query UserJoinedClassesByClassIDAndDate(
    $classID: ID!
    $date: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserJoinedClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userJoinedClassesByClassIDAndDate(
      classID: $classID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userCanceledClassID
        userCanceledClass {
          id
          userID
          classID
          date
          owner
          isCancelled
          createdAt
          updatedAt
          __typename
        }
        userID
        user {
          id
          createdAt
          updatedAt
          __typename
        }
        classID
        class {
          id
          classTypeID
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        date
        owner
        isCancelled
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userJoinedClassesByUserIDAndDate = /* GraphQL */ `
  query UserJoinedClassesByUserIDAndDate(
    $userID: ID!
    $date: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserJoinedClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userJoinedClassesByUserIDAndDate(
      userID: $userID
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userCanceledClassID
       userCanceledClass {
          id
          userID
          classID
          class {
            id
            classTypeID
            description
            time
            dayOfWeek
          }
          date
          owner
          isCancelled
          createdAt
          updatedAt
          __typename
        }
        userID
        user {
          id
          createdAt
          updatedAt
          __typename
        }
        classID
        class {
          id
          classTypeID
          classType {
            name
            description
            color
          }
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        date
        owner
        isCancelled
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userJoinedClassesByDateAndId = /* GraphQL */ `
  query UserJoinedClassesByDateAndId(
    $date: Int!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserJoinedClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userJoinedClassesByDateAndId(
      date: $date
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userCanceledClassID
        userCanceledClass {
          id
          userID
          classID
          date
          owner
          isCancelled
          createdAt
          updatedAt
          __typename
        }
        userID
        user {
          id
          createdAt
          updatedAt
          __typename
        }
        classID
        class {
          id
          classTypeID
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        date
        owner
        isCancelled
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCanceledClass = /* GraphQL */ `
  query GetCanceledClass($id: ID!) {
    getCanceledClass(id: $id) {
      id
      date
      description
      classID
      class {
        id
        classTypeID
        classType {
          id
          name
          description
          color
          createdAt
          updatedAt
          __typename
        }
        description
        time
        duration
        dayOfWeek
        maxAssist
        users {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCanceledClasses = /* GraphQL */ `
  query ListCanceledClasses(
    $id: ID
    $filter: ModelCanceledClassFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCanceledClasses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        date
        description
        classID
        class {
          id
          classTypeID
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const canceledClassesByDateAndId = /* GraphQL */ `
  query CanceledClassesByDateAndId(
    $date: Int!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCanceledClassFilterInput
    $limit: Int
    $nextToken: String
  ) {
    canceledClassesByDateAndId(
      date: $date
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        description
        classID
        class {
          id
          classTypeID
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCancelledDay = /* GraphQL */ `
  query GetCancelledDay($id: ID!) {
    getCancelledDay(id: $id) {
      id
      date
      description
      unavailableDay
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCancelledDays = /* GraphQL */ `
  query ListCancelledDays(
    $filter: ModelCancelledDayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCancelledDays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        description
        unavailableDay
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getClassUsers = /* GraphQL */ `
  query GetClassUsers($id: ID!) {
    getClassUsers(id: $id) {
      id
      classId
      userId
      class {
        id
        classTypeID
        classType {
          id
          name
          description
          color
          createdAt
          updatedAt
          __typename
        }
        description
        time
        duration
        dayOfWeek
        maxAssist
        users {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        classes {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        joinedClasses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listClassUsers = /* GraphQL */ `
  query ListClassUsers(
    $filter: ModelClassUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClassUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        classId
        userId
        class {
          id
          classTypeID
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const classUsersByClassId = /* GraphQL */ `
  query ClassUsersByClassId(
    $classId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClassUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classUsersByClassId(
      classId: $classId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classId
        userId
        class {
          id
          classTypeID
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const classUsersByUserId = /* GraphQL */ `
  query ClassUsersByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelClassUsersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    classUsersByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        classId
        userId
        class {
          id
          classTypeID
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        user {
          id
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
