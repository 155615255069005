import React, { useEffect, useState } from 'react';
import { Class } from '../../../../../models';
import { Modal, ScrollView, StyleSheet, TouchableOpacity, View, Text } from 'react-native';
import { UserExtended } from '../../../../../types';
import { addClassUser, admin_deleteClassUserById } from '../../../../../services/goulapi/goulapi.user';
import ClassTag from './ClassTag';
import ClassAdder from './ClassAdder';
import { Mutex } from 'async-mutex';

interface EditUserModalProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  userData: UserExtended;
  setUserData: (userData: UserExtended) => void;
  onClose: () => void;
  classList: Class[];
  setToReload: (toReload: boolean) => void;
}

const EditUserModal: React.FC<EditUserModalProps> = ({ visible, onClose, userData, setUserData, classList, setToReload }) => {
  const [selectedClassID, setSelectedClassID] = useState<string | null>(null);
  const [reloadTags, setReloadTags] = useState<boolean>(false);
  const mutex = new Mutex();

  async function handleAddClassID(classId: string) {
    await mutex.acquire();
    try {
      const response = await addClassUser(userData.userName, classId);
      if (response != null) {
        if (!userData.classes) {
          userData.classes = { items: [] };
        }
        userData.classes.items.push(response);
        setUserData(userData);
        setReloadTags(true);
      }
    } finally {
      mutex.release();
    }
  };

  useEffect(() => {
    if (reloadTags) {
      setReloadTags(false);
    }
  }, [reloadTags]);

  const handleDeleteClassID = (classID: string) => {
    setSelectedClassID(classID);
  };

  const handleConfirmDelete = async () => {
    await admin_deleteClassUserById(selectedClassID);
    setSelectedClassID(null);
    userData.classes.items = userData.classes.items.filter(item => item.id !== selectedClassID);
    setUserData(userData);
    setReloadTags(true);
  };

  const handleCancelDelete = () => {
    setSelectedClassID(null);
  };

  const handleRequestClose = () => {
    onClose();
    setToReload(true);
  };

  return userData || reloadTags ? (
    <Modal animationType="slide" transparent={true} visible={visible} onRequestClose={handleRequestClose}>
      <Modal animationType="slide" transparent={true} visible={selectedClassID != null}>
        <View style={styles.confirmationModal}>
          <Text style={styles.confirmationText}>Seguro que quieres eliminar esta clase?</Text>
          <View style={styles.confirmationButtons}>
            <TouchableOpacity style={styles.yesButton} onPress={handleConfirmDelete}>
              <Text style={styles.buttonText}>Yes</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.noButton} onPress={handleCancelDelete}>
              <Text style={styles.buttonText}>No</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      <TouchableOpacity style={styles.modalOverlay} activeOpacity={1}>
        <View style={styles.modalContent}>
          <ScrollView>
            <Text style={styles.label}>Nombre:</Text>
            <Text>{userData.name}</Text>

            <Text style={styles.label}>e-mail:</Text>
            <Text>{userData.email}</Text>

            <Text style={styles.label}>Teléfono:</Text>
            <Text>{userData.telf}</Text>

            <Text style={styles.label}>Clases actuales:</Text>
            <View style={styles.tagContainer}>
              {userData.classes ? Object.entries(userData.classes.items).sort((a, b) =>a[1].class.dayOfWeek - b[1].class.dayOfWeek).map(([key, classSel]) => (
                <ClassTag onDelete={handleDeleteClassID} key={key} tagClass={classSel} isCloseable={true} />
              )) : null}
            </View>
            <View style={styles.pickerContainer}>
              <ClassAdder classList={classList} onSave={handleAddClassID}></ClassAdder>
            </View>
          </ScrollView>

          <TouchableOpacity onPress={onClose}>
            <Text style={styles.closeButton}>Cerrar</Text>
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    </Modal>
  ) : null;
};

const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    width: '80%',
    maxHeight: '80%',
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    overflow: 'hidden',
  },
  label: {
    fontWeight: 'bold',
    marginTop: 10,
  },
  tagContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 5,
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  input: {
    flex: 1,
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginRight: 10,
    paddingLeft: 10,
    borderRadius: 5,
  },
  closeButton: {
    marginTop: 10,
    color: 'blue', // Change the color as needed
    textAlign: 'center',
  },
  confirmationModal: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  confirmationText: {
    marginBottom: 20,
    color: 'white',
  },
  confirmationButtons: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  yesButton: {
    backgroundColor: '#7CFC00', // Apple green color
    padding: 10,
    borderRadius: 10,
    marginTop: 10, // Increased separation
    marginRight: 5
  },
  noButton: {
    backgroundColor: 'red',
    padding: 10,
    borderRadius: 10,
    marginTop: 10, // Increased separation
    marginLeft: 5
  },
  buttonText: {
    color: 'white',
    textAlign: 'center',
  },
  pickerContainer: {
    flexDirection: 'row',
    marginTop: 10,
  },
  picker: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 10,
  },
});

export default EditUserModal;