import { createContext, useState } from 'react';

import {View, ActivityIndicator, Modal, SafeAreaView, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  loadingModal: {
    width: '80%',
    maxHeight: '80%',
    padding: 20,
    borderRadius: 10,
    overflow: 'hidden',
    alignContent: 'center'
  }
});

const LoadingContext = createContext();

const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [whoAmI, setWhoAmI] = useState({userName:'', email: '', name: ''});

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading, whoAmI, setWhoAmI }}>
      <Modal style={styles.loadingModal} animationType="fade" transparent={true} visible={isLoading} >
        <View style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <ActivityIndicator size="large" style={{ transform: [{ scale: 1.5 }] }} />
        </View>
      </Modal>
      {children}
    </LoadingContext.Provider>
  );
};

export { LoadingProvider, LoadingContext };