import React, { useEffect, useState } from 'react';
import { View, TextInput, Pressable, FlatList, Modal, Text, StyleSheet } from 'react-native';

import { createClassType, deleteClassType, listClassTypes, updateClassType } from '../../../services/goulapi/goulapi.classtype';

const ClassTypeView = () => {
  const [input, setInput] = useState({
    name: '',
    description: '',
    color: ''
  });
  const [modalVisibleCreate, setModalVisibleCreate] = useState(false);
  const [modalVisibleUpdate, setModalVisibleUpdate] = useState(false);
  const [modalVisibleDelete, setModalVisibleDelete] = useState(false);

  const [classTypes, setClassTypes] = useState([]);

  const fetchClassTypes = async () => {
    try {
      const classTypesData = await listClassTypes();
      setClassTypes(classTypesData);
    } catch (error) {
      console.log('Error retrieving classTypes:', error);
    }
  };

  useEffect(() => {
    fetchClassTypes();
  }, []);

  const handleSubmit = async () => {
    // Perform the GraphQL mutation here using the input values
    // After successfully creating the class type, add it to the list
    const newClassType = {
      name: input.name,
      description: input.description,
      color: input.color,
    };
    const response = createClassType(newClassType);
    fetchClassTypes();
    setInput({ name: '', description: '', color: '' });
    setModalVisibleCreate(false);
    setModalVisibleUpdate(false);
    setModalVisibleDelete(false);
  };

  const handleEdit = async (item) => {
    // Perform the GraphQL mutation here using the input values
    // After successfully creating the class type, add it to the list
    const classType = {
      id: input.id,
      name: input.name,
      description: input.description,
      color: input.color,
    };
    const response = updateClassType(classType);

    fetchClassTypes();
    setInput({ name: '', description: '', color: '' });
    setModalVisibleCreate(false);
    setModalVisibleUpdate(false);
    setModalVisibleDelete(false);
  };

  const handleDelete = async (item) => {
    const response = deleteClassType(item.id);

    fetchClassTypes();
    setInput({ name: '', description: '', color: '' });
    setModalVisibleCreate(false);
    setModalVisibleUpdate(false);
    setModalVisibleDelete(false);
  };

  const renderItem = ({ item }) => (
    <View style={styles.row}>
            <View style={[styles.column, { flex: 0.5 }]}>
        <Text style={styles.field}>{item.name}</Text>
      </View>
      <View style={[styles.column, { flex: 0.5 }]}>
        <Text style={styles.field}>{item.description}</Text>
      </View>
      <View style={[styles.column, { flex: 0.3 }]}>
        <Text style={styles.field}>{item.color}</Text>
      </View>
      <View style={[styles.column, { flex: 0.5 }]}>
        <Pressable onPress={() => setModalVisibleUpdate(true)}>
          <Text style={styles.button}>Editar</Text>
        </Pressable>
      </View>
      <View style={[styles.column, { flex: 0.5 }]}>
        <Pressable onPress={() => setModalVisibleDelete(true)}>
          <Text style={styles.button}>Borrar</Text>
        </Pressable>
      </View>
      <Modal visible={modalVisibleUpdate} animationType="slide">
        <View style={styles.modalContent}>
        <TextInput
            placeholder={item.name}
            value={input.name}
            onChangeText={(Text) => setInput({ ...input, name: Text })}
          />
          <TextInput
            placeholder={item.description}
            value={input.description}
            onChangeText={(Text) => setInput({ ...input, description: Text })}
          />
          <TextInput
            placeholder={item.color}
            value={input.color}
            onChangeText={(Text) => setInput({ ...input, color: Text })}
          />
          <Pressable onPress={() => handleEdit(item)}>
            <Text style={styles.button}>Submit</Text>
          </Pressable>
          <Pressable onPress={() => setModalVisibleUpdate(false)}>
            <Text style={styles.button}>Cancel</Text>
          </Pressable>
        </View>
      </Modal>
      <Modal visible={modalVisibleDelete} animationType="slide">
        <View style={styles.modalContent}>
          <Text>Are you sure that want to delete the class type '{item.name}' ?</Text>
          <Pressable onPress={() => handleDelete(item)}>
            <Text style={styles.button}>Yes</Text>
          </Pressable>
          <Pressable onPress={() => setModalVisibleDelete(false)}>
            <Text style={styles.button}>Cancel</Text>
          </Pressable>
        </View>
      </Modal>
    </View>
  );

  return (
    <View style={styles.container}>
      <Pressable onPress={() => setModalVisibleCreate(true)}>
        <Text style={styles.button}>Add</Text>
      </Pressable>
      <Modal visible={modalVisibleCreate} animationType="slide">
        <View style={styles.modalContent}>
        <TextInput
            placeholder="Name"
            value={input.name}
            onChangeText={(Text) => setInput({ ...input, name: Text })}
          />
          <TextInput
            placeholder="Description"
            value={input.description}
            onChangeText={(Text) => setInput({ ...input, description: Text })}
          />
          <TextInput
            placeholder="Color"
            value={input.color}
            onChangeText={(Text) => setInput({ ...input, color: Text })}
          />
          <Pressable onPress={handleSubmit}>
            <Text style={styles.button}>Submit</Text>
          </Pressable>
          <Pressable onPress={() => setModalVisibleCreate(false)}>
            <Text style={styles.button}>Cancel</Text>
          </Pressable>
        </View>
      </Modal>
      <FlatList
        data={classTypes}
        keyExtractor={(item) => item.id}
        renderItem={renderItem}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  modalContent: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 20,
    paddingTop: 100, // Add more padding to the top side
    borderRadius: 10,
    elevation: 5,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  column: {
    flex: 1,
    marginHorizontal: 5,
  },
  field: {
    fontSize: 16,
    marginBottom: 5,
  },
  button: {
    fontSize: 16,
    marginTop: 5,
    color: 'blue',
  },
});

export default ClassTypeView