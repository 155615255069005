import React, { useState } from 'react';
import { Text, StyleSheet, Modal, View, TextInput, TouchableOpacity } from 'react-native';
//Antd
import Button from '@ant-design/react-native/lib/button';

interface AddButtonProps {
    buttonText: string;
    onSave: (email: string, name: string) => void;
}

const AddButton: React.FC<AddButtonProps> = ({ buttonText, onSave }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const handleSave = () => {
        onSave(email, name);
        setName('');
        setEmail('');
        setModalVisible(false);
    };

    const handleClose = () => {
        // Reset input fields and close the modal
        setName('');
        setEmail('');
        setModalVisible(false);
    };

    return (
        <>
            <Button style={styles.button} onPress={() => setModalVisible(true)}>
                <Text style={styles.buttonText}>
                    {buttonText}
                </Text>
            </Button>

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => setModalVisible(false)}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <TextInput
                            style={styles.input}
                            placeholder="Nombre"
                            value={name}
                            onChangeText={(text) => setName(text)}
                        />
                        <TextInput
                            style={styles.input}
                            placeholder="Email"
                            value={email}
                            onChangeText={(text) => setEmail(text)}
                        />

                        <View style={styles.buttonContainer}>
                            <TouchableOpacity style={[
                                styles.saveButton,
                                { backgroundColor: name && email ? styles.saveButton.backgroundColor : '#D3D3D3' },
                            ]} onPress={handleSave} disabled={!(name && email)}>
                                <Text style={styles.buttonText}>Save</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.closeButton} onPress={handleClose}>
                                <Text style={styles.buttonText}>Close</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>
        </>
    );
};

export default AddButton;

const styles = StyleSheet.create({
    button: {
        backgroundColor: '#95EC91',
        borderColor: '#6DFE4A',
        borderRadius: 8,
        padding: 10,
    },
    buttonText: {
        letterSpacing: 1,
        fontSize: 13,
        textAlign: 'center',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: 'white',
        borderRadius: 8,
        padding: 20,
        width: 300,
    },
    input: {
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        marginVertical: 10,
        borderRadius: 8,
        paddingLeft: 10,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    saveButton: {
        backgroundColor: '#95EC91',
        borderColor: '#6DFE4A',
        borderRadius: 8,
        padding: 10,
        alignItems: 'center',
        flex: 1,
        marginRight: 5,
    },
    closeButton: {
        backgroundColor: '#FF6F6F',
        borderColor: '#FF4646',
        borderRadius: 8,
        padding: 10,
        alignItems: 'center',
        flex: 1,
        marginLeft: 5,
    },
});