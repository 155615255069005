import React, { useContext, useEffect, useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, TouchableWithoutFeedback, Dimensions, ScrollView } from 'react-native';
import { CalendarList, LocaleConfig } from 'react-native-calendars';
import { Ionicons } from '@expo/vector-icons';
import { LoadingContext } from '../../../components/LoadingContext';
import YesNoModal from '../../../modals/YesNoModal';
import { Class } from '../../../models';
import { getClassesFromRange, admin_cancelDay, getCancelledDays } from '../../../services/goulapi/goulapi.service';
import { getUsersAndInfo } from '../../../services/goulapi/goulapi.user';
import CalendarClass from './component/CalendarClass';
import { getCancelledDayFormat } from '../../../utils/dateUtils';

LocaleConfig.defaultLocale = 'es';
LocaleConfig.locales['es'] = {
    monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
    ],
    monthNamesShort: ['Ene.', 'Feb.', 'Mar.', 'Abr.', 'May.', 'Jun.', 'Jul.', 'Ago.', 'Sep.', 'Oct.', 'Nov.', 'Dic.'],
    dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    dayNamesShort: ['Dom.', 'Lun.', 'Mar.', 'Mié.', 'Jue.', 'Vie.', 'Sáb.'],
    today: 'Hoy'
};

const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
        overflow: 'hidden',
    },
    calendarContainer: {
        flex: 1,
        zIndex: 0,
    },
    sidebarContainer: {
        width: windowWidth >= 600 ? '25%' : '100%',
        backgroundColor: 'white',
        padding: 20,
        borderLeftWidth: 1,
        borderColor: '#e0e0e0',
        zIndex: 1,
    },
    closeButton: {
        position: 'absolute',
        top: 5,
        right: 10,
        padding: 10,
        borderRadius: 5,
        zIndex: 2,
    },
    closeIcon: {
        color: '#CACBCA', // Greyish color
        fontSize: 30,
    },
    shadow: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        zIndex: 0,
    },
    calendarTheme: {
        backgroundColor: '#ffffff',
        calendarBackground: '#ffffff',
        textSectionTitleColor: '#b6c1cd',
        textSectionTitleDisabledColor: '#d9e1e8',
        selectedDayBackgroundColor: '#AFFE7F',
        selectedDayTextColor: '#ffffff',
        todayTextColor: 'red',
        dayTextColor: '#2d4150',
        textDisabledColor: '#d9e1e8',
        monthTextColor: '#FE8C7F',
        indicatorColor: 'red',
        textDayFontWeight: '300',
        textMonthFontWeight: 'bold',
        textDayHeaderFontWeight: '300',
        textDayFontSize: 16,
        textMonthFontSize: 15,
        textDayHeaderFontSize: 16,
    },
    dayHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start', // Updated to justify to the right
        marginBottom: 10,
    },
    daysubHeader: {
        flexDirection: 'column',
        justifyContent: 'flex-start', // Updated to justify to the right
        marginBottom: 10,
    },
    dayNumber: {
        width: 50, // Updated to make the circle slightly larger
        height: 50, // Updated to make the circle slightly larger
        borderRadius: 25, // Updated to make the circle slightly larger
        backgroundColor: '#FEA47F', // You can set the desired color
        marginLeft: 10, // Updated to create space between the number and text
        alignItems: 'center',
        justifyContent: 'center',
    },
    dayText: {
        fontWeight: 'bold',
        fontSize: 20, // Updated to make the number bigger
        color: 'white', // Updated to set the font color to white
    },
    dayNameText: {
        fontSize: 22, // Updated to make the number bigger
        marginLeft: 7,
        textTransform: 'capitalize', // Added to capitalize the first letter
    },
    monthNameText: {
        fontSize: 14, // Updated to make the number bigger
        color: 'lightgrey',
        marginLeft: 7,
        textTransform: 'capitalize', // Added to capitalize the first letter
    },
    buttonDelete: {
        padding: 10,
        borderRadius: 5,
        borderWidth: 0, // This removes the border
    },
    buttonDeleteText: {
        color: 'red',
        textAlign: 'center',
    }
});

function AdminCalendarView() {
    const [selectedDay, setSelectedDay] = useState('');
    var selectedDayClasses = [];
    var isDayCanceled = 0;
    const [toReload, setToReload] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const { isLoading, setIsLoading } = useContext(LoadingContext);
    const [userInfo, setUserInfo] = useState([]);

    const customSort = (a, b) => {
        const [aHours, aMinutes] = a.time.split(/[:.]/).map(Number);
        const [bHours, bMinutes] = b.time.split(/[:.]/).map(Number);

        if (aHours !== bHours) {
            return aHours - bHours;
        }
        return aMinutes - bMinutes;
    };

    const handleDayPress = (day) => {
        setSelectedDay(day.dateString);
    };

    const closeSidebar = () => {
        setSelectedDay('');
    };

    function handleCancelClassDay(day: Date) {
        admin_cancelDay(day);
        setToReload(true);
    }

    function handleCancelClassDayHoliday(day: Date) {
        admin_cancelDay(day, "", true);
        setToReload(true);
    }

    const renderDayHeader = () => {
        const selectedDate = new Date(selectedDay);
        const dayNumber = selectedDate.getDate();
        const dayName = new Intl.DateTimeFormat('es', { weekday: 'long' }).format(selectedDate);
        const month = new Intl.DateTimeFormat('es', { month: 'long' }).format(selectedDate);

        return (<>
            <View style={styles.dayHeader}>
                <View style={styles.dayNumber}>
                    <Text style={styles.dayText}>{dayNumber}</Text>
                </View>
                <View style={styles.daysubHeader}>
                    <Text style={styles.monthNameText}>{month}</Text>
                    <Text style={styles.dayNameText}>{dayName}</Text>
                </View>
            </View>
            {(selectedDayClasses.length > 0 ?
                <View> {(isDayCanceled == 0 ? 
                    <>
                        <YesNoModal
                            buttonText="Cancelar clases"
                            buttonStyle={styles.buttonDelete}
                            buttonTextStyle={styles.buttonDeleteText}
                            question={"¿Estas seguro que quieres cancelar todas las clases del dia " + selectedDay + "?"}
                            confirmationMessage="Se eliminarán todas las clases reagendadas en este dia, esta accion no se puede deshacer. ¿Deseas continuar?"
                            handleYes={() => handleCancelClassDay(selectedDate)}
                            handleNo={() => { }}
                        />
                        <YesNoModal
                            buttonText="Cancelar clases por vacaciones"
                            buttonStyle={styles.buttonDelete}
                            buttonTextStyle={styles.buttonDeleteText}
                            question={"¿Estas seguro que quieres cancelar todas las clases del dia " + selectedDay + "?"}
                            confirmationMessage="Se eliminarán todas las clases reagendadas en este dia, esta accion no se puede deshacer. Los usuarios no podrán reagendar esta clase ¿Deseas continuar?"
                            handleYes={() => handleCancelClassDayHoliday(selectedDate)}
                            handleNo={() => { }}
                        />
                    </> :
                     <>{(isDayCanceled == 1 ? 
                        <Text>Clases canceladas por vacaciones</Text> 
                        :<Text>Clases canceladas</Text>
                        )}
                     </>)}
                </View> :
                 <></>)}
            
            </>
        );
    };

    const renderShadow = () => {
        return (
            <TouchableWithoutFeedback onPress={closeSidebar}>
                <View style={styles.shadow} />
            </TouchableWithoutFeedback>
        );
    };

    const renderCalendar = () => {
        return (
            <View style={styles.calendarContainer}>
                <CalendarList
                    theme={styles.calendarTheme}
                    firstDay={1} //Start on Modnay
                    pastScrollRange={1}
                    futureScrollRange={1}
                    markedDates={{ [selectedDay]: { selected: true } }}
                    onDayPress={handleDayPress}
                />
            </View>
        );
    }

    const renderCalendarWithSideBar = () => {
        return (
            <>
                {renderCalendar()}
                <View style={styles.sidebarContainer}>
                    <TouchableOpacity style={styles.closeButton} onPress={closeSidebar}>
                        <Ionicons style={styles.closeIcon} name="md-close-outline" />
                    </TouchableOpacity>
                    {renderDayHeader()}
                    <ScrollView>
                        {selectedDayClasses.map((selClass: Class, key) => (
                            <CalendarClass key={key} selectedClass={selClass} users={userInfo} setToReload={setToReload}></CalendarClass>
                        ))}
                    </ScrollView>
                </View>
                {renderShadow()}
            </>
        );
    }

    const [calendarView, setCalendarView] = useState<any>(<>{renderCalendar()}</>);

    async function setView() {
        if (selectedDay == '') {
            selectedDayClasses = [];
            setCalendarView(<>{renderCalendar()}</>);
        }
        else {
            const selectedDate = new Date(selectedDay);
            const unorderSelectedDayClasses = await getClassesFromRange(selectedDate, selectedDate);
            selectedDayClasses = unorderSelectedDayClasses.sort(customSort);
            const selWeekFormat = getCancelledDayFormat(selectedDate);
            const cancellDay = await getCancelledDays(selWeekFormat);
            isDayCanceled = cancellDay.length > 0 ? cancellDay[0].unavailableDay ? 1 : 2 : 0 ;
            setCalendarView(<>{renderCalendarWithSideBar()}</>);
        }
        setIsLoading(false);
        setToReload(false);
    }

    useEffect(() => {
        async function getUsersFromCognito() {
            setUserInfo(await getUsersAndInfo());
            setIsLoading(false);
            setFirstLoad(false);
        }

        if (firstLoad) {
            setIsLoading(true);
            getUsersFromCognito();
        }
    }, [firstLoad]);

    useEffect(() => {
        if (!firstLoad) {
            setIsLoading(true);
            setView();
        }
    }, [selectedDay]);

    useEffect(() => {
        if (!firstLoad) {
            setIsLoading(true);
            setView();
        }
    }, [toReload]);

    return (
        <View style={styles.container}>
            {calendarView}
        </View>
    );
}

export default AdminCalendarView;