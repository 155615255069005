import React, { useState } from 'react';
import { Text, StyleSheet, Modal, View, TextInput, TouchableOpacity } from 'react-native';
import { generateTimeArray } from '../../../../utils/dateUtils'
//Antd
import Button from '@ant-design/react-native/lib/button';
import { Class, ClassType } from '../../../../models';
import { Picker } from '@react-native-picker/picker';

interface AddClassButtonProps {
    classTypes: ClassType[];
    buttonText: string;
    onSave: (session: Class) => void;
}

const AddClassButton: React.FC<AddClassButtonProps> = ({ classTypes, buttonText, onSave }) => {
    var daysOfWeek = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes"];
    var times = generateTimeArray("8:00", "22:00", 15)
    const [modalVisible, setModalVisible] = useState(false);
    const [classTypeID, setClassTypeID] = useState('');
    const [description, setDescription] = useState('');
    const [dayOfWeek, setDayOfWeek] = useState(null);
    const [time, setTime] = useState('');
    const [duration, setDuration] = useState(null);
    const [maxAssist, setMaxAssists] = useState(null);

    function resetFields() {
        setClassTypeID(null);
        setDescription('')
        setDayOfWeek(null);
        setTime('');
        setDuration(null);
        setMaxAssists(null);
    }

    const handleSave = () => {
        const newClass = {
            classTypeID: classTypeID,
            description: description,
            time: time,
            duration: duration,
            maxAssist: maxAssist,
            dayOfWeek: dayOfWeek
        }
        onSave(newClass);
        resetFields();
        setModalVisible(false);
    };

    const handleClose = () => {
        resetFields();
        setModalVisible(false);
    };

    const isButtonDisabled = () => {
        return classTypeID && description && time && duration && maxAssist && dayOfWeek;
    }

    return (
        <>
            <Button style={styles.button} onPress={() => setModalVisible(true)}>
                <Text style={styles.buttonText}>
                    {buttonText}
                </Text>
            </Button>

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => setModalVisible(false)}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Picker
                            style={styles.picker}
                            selectedValue={classTypeID}
                            onValueChange={(itemValue: string) => {
                                setClassTypeID(itemValue);
                            }}
                        >
                            <Picker.Item key={0} label={''} value={null} />
                            {classTypes.map((classType, key) => (
                                <Picker.Item key={key + 1} label={classType.name} value={classType.id} />
                            ))}
                        </Picker>
                        <TextInput
                            style={styles.input}
                            placeholder="Descripción"
                            value={description}
                            onChangeText={(text) => setDescription(text)}
                        />
                        <Picker
                            style={styles.picker}
                            selectedValue={dayOfWeek}
                            onValueChange={(itemValue: number) => {
                                setDayOfWeek(itemValue);
                            }}
                        >
                            <Picker.Item key={0} label={''} value={null} />
                            {daysOfWeek.map((day, key) => (
                                <Picker.Item key={key + 1} label={day} value={key + 1} />
                            ))}
                        </Picker>
                        <Picker
                            style={styles.picker}
                            selectedValue={time}
                            onValueChange={(itemValue: string) => {
                                setTime(itemValue);
                            }}
                        >
                            <Picker.Item key={0} label={''} value={null} />
                            {times.map((time, key) => (
                                <Picker.Item key={key + 1} label={time} value={time} />
                            ))}
                        </Picker>
                        <TextInput
                            style={styles.input}
                            placeholder="Máximo número de asistentes"
                            value={maxAssist}
                            onChangeText={(text) => setMaxAssists(parseInt(text))}
                        />
                        <TextInput
                            style={styles.input}
                            placeholder="Duración (en minutos)"
                            value={duration}
                            onChangeText={(text) => setDuration(parseInt(text))}
                        />

                        <View style={styles.buttonContainer}>
                            <TouchableOpacity style={[
                                styles.saveButton,
                                { backgroundColor: isButtonDisabled() ? styles.saveButton.backgroundColor : '#D3D3D3' },
                            ]} onPress={handleSave} disabled={!(isButtonDisabled())}>
                                <Text style={styles.buttonText}>Save</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.closeButton} onPress={handleClose}>
                                <Text style={styles.buttonText}>Close</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>
        </>
    );
};

export default AddClassButton;

const styles = StyleSheet.create({
    button: {
        backgroundColor: '#95EC91',
        borderColor: '#6DFE4A',
        borderRadius: 8,
        padding: 10,
    },
    buttonText: {
        letterSpacing: 1,
        fontSize: 13,
        textAlign: 'center',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: 'white',
        borderRadius: 8,
        padding: 20,
        width: 300,
    },
    input: {
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        marginVertical: 10,
        borderRadius: 8,
        paddingLeft: 10,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    saveButton: {
        backgroundColor: '#95EC91',
        borderColor: '#6DFE4A',
        borderRadius: 8,
        padding: 10,
        alignItems: 'center',
        flex: 1,
        marginRight: 5,
    },
    closeButton: {
        backgroundColor: '#FF6F6F',
        borderColor: '#FF4646',
        borderRadius: 8,
        padding: 10,
        alignItems: 'center',
        flex: 1,
        marginLeft: 5,
    },
    picker: {
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 5,
        padding: 10,
        marginBottom: 10,
        marginRight: 15,
        //minWidth: '25%', // Minimum width of 1/4 the container
    },
});