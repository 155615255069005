
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { User, UserCanceledClass, UserJoinedClass } from "../../models";
import { getWeekNumber } from "../../utils/dateUtils";
import { Auth } from 'aws-amplify';
import { listOfUsers } from '../cognito/cognito.service';
import { getClass } from './goulapi.class';
import { baseListGQLRequest, baseGQLRequest, getWeekFilterAndNotCancelled } from './goulapi.utils';

export async function getUsersAndInfo(): Promise<any[]> {
    try {
        const findAttinUser = (user, val) => {
            const filter = Object.entries(user.Attributes).find(([key, att]) => att?.Name === val);
            if(filter)
            {
                return filter[1].Value;
            }
            return '';
        }

        const usersCognito = await listOfUsers((await Auth.currentSession()).getAccessToken().getJwtToken());
        //filter Admin user
        const adminIndex = usersCognito.findIndex(u => findAttinUser(u, 'name')=== 'Admin 01');
        if(adminIndex !== -1) {
            usersCognito.splice(adminIndex, 1);
        }
        const usersAPI = await getUsers() || [User];
        const output = usersCognito.filter((user, key) => user.Enabled).map((user, key) => (
                {...usersAPI.find(u => u.id === user.Username), userName: user.Username ,email: findAttinUser(user, 'email'), name: findAttinUser(user, 'name'), telf: findAttinUser(user, 'phone_number'), status: user.UserStatus}) 
            );
        output.sort((a, b) => a.name.localeCompare(b.name));
        return output;
    } catch (error) {
        console.log('Error retrieving getUsersAndInfo:', error);
        return [];
    }
}

async function queriesListUserCanceledClasses(variables: any): Promise<any[]> {
    return await baseListGQLRequest(variables, queries.listUserCanceledClasses);
}

async function queriesListUserCanceledClassesByUser(userID: string, dateFrom: number, dateTo: number, filter: any): Promise<any[]> {
    var variables;
    if(dateFrom == dateTo){
        variables = {userID: userID, date: {eq: dateFrom}};
    }
    else
    {
        variables = {userID: userID, date: {between: [dateFrom, dateTo]}};
    }
    
    variables.filter = filter;
    return await baseListGQLRequest(variables, queries.userCanceledClassesByUserIDAndDate);
}

export async function getUsersSessionsJoinedFromUser(userID: string, dateFrom: number = 0, dateTo: number = 999999): Promise<any[]> {
    //TODO add also day of week filter
    //TODO add classID filter if it is different than ''
    var variables;
    if(dateFrom == dateTo){
        variables = {userID: userID, date: {eq: dateFrom}};
    }
    else
    {
        variables = {userID: userID, date: {between: [dateFrom, dateTo]}};
    }
    variables.filter = {isCancelled: {ne: true}};
    return await baseListGQLRequest(variables, queries.userJoinedClassesByUserIDAndDate);
}

// Users
export async function getUsers(): Promise<any[]> {
    return await baseListGQLRequest({}, queries.listUsers);
}

export async function getUser(userId: string): Promise<any[]> {
    return await baseGQLRequest({id: userId}, queries.getUser);
}

export async function addUser(newUserName: string): Promise<void> {
    const newUser = { id: newUserName }
    return await baseGQLRequest({input: newUser}, mutations.createUser);
}

export async function removeUser(userId: string): Promise<void> {
    const userToDelete = { id: userId };
    return await baseGQLRequest({input: userToDelete}, mutations.deleteUser);
}

export async function updateUser(updatedUser: User): Promise<void> {
    return await baseGQLRequest({input: updatedUser}, mutations.updateUser);
}

export async function getClassUser(userId: string, classId: string): Promise<any[]> {
        let items: any[] = [];
        const variables = {
            filter: {
                and: [{ classId: { eq: classId } }, { userId: { eq: userId } }]
            }
        };
        return await baseListGQLRequest(variables, queries.listClassUsers);
}

export async function getClassUserByClassId( classId: string): Promise<any[]> {
    let items: any[] = [];
    const variables = {
        filter: {
            and: [{ classId: { eq: classId } }]
        }
    };
    return await baseListGQLRequest(variables, queries.listClassUsers);
}

export async function addClassUser(userId: string, classId: string): Promise<void> {
    const existingClassUser = await getClassUser(userId, classId);

    if (existingClassUser?.length > 0) {
        throw new Error('User is already in the class');
    }

    if (!(await getClass(classId))) {
        throw new Error('Class does not exist');
    }

    if (!(await getUser(userId))) {
        await addUser(userId);
    }

    var response = await baseGQLRequest({
        input: {
            classId: classId,
            userId: userId
        }
    }, mutations.createClassUsers);
    
    return response;
}

export async function admin_deleteClassUserById(classUserId: string): Promise<void> {
    try {
        const response = await baseGQLRequest({ input: { id: classUserId } }, mutations.deleteClassUsers);

        const userID = response.userId;
        const classID = response.classId;
        //TODO instead of use this call use the call before and add a filter with the maximum posible date
        const userCancelledSessions = await baseListGQLRequest({userID: userID, date: {ge: getWeekNumber(new Date())}}, queries.userCanceledClassesByUserIDAndDate);
        for (const userCancelledSession of userCancelledSessions) {
            if(userCancelledSession.userJoinedClass.items.length > 0){
                removeUserSessionJoined(userCancelledSession.userJoinedClass.items[0].id);
            }
            removeUserSessionCancelled(userCancelledSession.id);
        }

    } catch (error) {
        console.log('Error retrieving deleteClassUser', error);
    }
}

export async function admin_deleteClassUser(userId: string, classId: string): Promise<void> {
    try {
        const classUser = await getClassUser(userId, classId);
        if(classUser.length > 0)
        {
            await admin_deleteClassUserById(classUser[0].id);
        }
        
    } catch (error) {
        console.log('Error retrieving deleteClassUser', error);
    }
}

export async function getUsersSessionCancelled(weekFrom: number = 0, weekTo: number = 999999): Promise<any[]> {
    if(weekFrom == weekTo)
    {
        return await baseListGQLRequest({date: weekFrom, filter: {isCancelled: {ne: true}}}, queries.userCanceledClassesByDateAndId)
    }
    else
    {
        return await queriesListUserCanceledClasses(getWeekFilterAndNotCancelled(weekFrom, weekTo));    
    }   
}

export async function getUsersSessionJoined(weekFrom: number = 0, weekTo: number = 999999): Promise<any[]> {
    if(weekFrom == weekTo)
    {
        return await baseListGQLRequest({date: weekFrom, filter: {isCancelled: {ne: true}}}, queries.userJoinedClassesByDateAndId);
    }
    else
    {
        return await baseListGQLRequest(getWeekFilterAndNotCancelled(weekFrom, weekTo), queries.listUserJoinedClasses);    
    }   
}

export async function getUsersSessionsJoinedByClassIdAndWeek(classId: number, weekFrom: number): Promise<any[]> {

    return await baseListGQLRequest({classID: classId, date: {ge: weekFrom}}, queries.userJoinedClassesByClassIDAndDate);
}

async function getUserCancelledClassByID(userCanceledClassID: number): Promise<UserCanceledClass> {
    return await baseGQLRequest({ id: userCanceledClassID }, queries.getUserCanceledClass);
}

export async function createUserJoinedClass(userCanceledClassID: number, userId: string, classId: string, date: string): Promise<UserJoinedClass> {
    try {
        const fecha = getWeekNumber(new Date(date));
        var ret;
        //Check if user has already joined to the class
        var userCancelledClass = await getUserCancelledClassByID(userCanceledClassID);
        if (userCancelledClass.userJoinedClass.items.length > 0) {
            throw new Error('User is already in the class');
        }
        if(userCancelledClass.classID == classId){
            ret = await removeUserSessionCancelled(userCancelledClass.id);
        }
        else {
            //Join user to the class
            const newJoinedClass = {  
                userID: userId,
                classID: classId, 
                date: fecha,
                userCanceledClassID: userCanceledClassID
            }

            ret = await baseGQLRequest({ input: newJoinedClass }, mutations.createUserJoinedClass);
        }
        return ret;
        
    } catch (error) {
        console.log('Error retrieving createUserJoinedClass', error);
    }
}

async function getUserCancelledClassByUserIDAndClassIDAndDate(userId: string, classId: string, dateNumber: number): Promise<any[]> {    
    return await queriesListUserCanceledClassesByUser(userId, dateNumber, dateNumber, {classID: {eq: dateNumber}, isCancelled: { eq: false }});
}

export async function createUserCanceledClass(userId: string, classId: string, date: string): Promise<UserCanceledClass> {
    try {
        const fecha = getWeekNumber(new Date(date));

        //Check if user has already cancelled the class
        const existingUserCancelledClass = await getUserCancelledClassByUserIDAndClassIDAndDate( userId, classId, fecha);
        if (existingUserCancelledClass.length > 0) {
            throw new Error('User is already in the class');
        }

        //Cancel the class to the user
        const newCanceledClass = {  
            userID: userId,
            classID: classId, 
            date: fecha
        }

        return await baseGQLRequest({ input: newCanceledClass }, mutations.createUserCanceledClass);

    } catch (error) {
        console.log('Error retrieving createUserCanceledClass', error);
    }
}

export async function admin_createUserCanceledClass(userId: string, classId: string, date: string): Promise<UserCanceledClass> {
    try {
        const fecha = getWeekNumber(new Date(date));

        //Check if user has already cancelled the class
        const existingUserCancelledClass = await getUserCancelledClassByUserIDAndClassIDAndDate( userId, classId, fecha);
        if (existingUserCancelledClass.length > 0) {
            throw new Error('User is already in the class');
        }

        //Cancel the class to the user
        const newUserCanceledClass = {  
            userID: userId,
            classID: classId, 
            date: fecha,
            owner: userId
        }
        
        return await baseGQLRequest({ input: newUserCanceledClass }, mutations.createUserCanceledClass);

    } catch (error) {
        console.log('Error retrieving createUserCanceledClass', error);
    }
}

export async function getUsersSessionsCancelledFromUser(userId: string, weekFrom: number = 0, weekTo: number = 999999): Promise<UserCanceledClass[]> {
    return await queriesListUserCanceledClassesByUser(userId, weekFrom, weekTo, {});
}

export async function removeUserSessionJoined(userJoinedID: string): Promise<void> {
    const joinedSessionToCancel = { id: userJoinedID, isCancelled: true };
    return await baseGQLRequest({ input: joinedSessionToCancel }, mutations.updateUserJoinedClass);
}

export async function removeUserSessionCancelled(userSessionCancelledID: string): Promise<void> {
    const userCancelledSessionRemove = { id: userSessionCancelledID, isCancelled: true };
    return await baseGQLRequest({ input: userCancelledSessionRemove }, mutations.updateUserCanceledClass);
}