import {  UserClassSession } from '../types';
import {  getWeekNumber, dateDB_to_date, getDaysFrom } from './dateUtils';

export const setClassSessionToAgendaFormat = (startDate: Date, endDate: Date, result: UserClassSession[]) => {
  //TODO solve bug about the week and the filter, that cannot be the first day of the week. Ej 1 Frabrary is week 5, but it start on wenednesday. monday and thursday are the first days of the week is not covered.   
  const agendaFormat = getDaysFrom(dateDB_to_date(getWeekNumber(startDate),1), endDate);
  result.map((clase: UserClassSession) => {
    agendaFormat[clase.date].push({description: clase.description, day: clase.date, height:1, classID: clase.id, classTypeID: clase.classTypeID, classType: clase.classType, time: clase.time, duration: clase.duration, numOfAssist: clase.numOfAssist, maxAssist: clase.maxAssist, users: clase.users, state: clase.state, canceledClassID: clase.canceledClassID, cancelledClass: clase.userCancelledClass, joinedClass: clase.userJoinedClass, joinedID: clase.joinedID});
  })
  return agendaFormat;
};


export const setClassSessionToCalendarFormat = (selectedDay: string, result) => {
  const calendarFormat = {};
  Object.keys(result).map((day) => {
      calendarFormat[day] = {
        marked: false,
        selected: false
      };
      if(result[day].length)
      {
        calendarFormat[day].marked = true;
      }
  })

  if(calendarFormat[selectedDay])
  {
    calendarFormat[selectedDay].selected = true;
  }

  return calendarFormat;
};