import React from 'react'
import { createStackNavigator } from '@react-navigation/stack';
import CustomCalendarView from './CustomCalendar';
import UserCalendarView from './UserCalendarView';

const Stack = createStackNavigator();
const UserCalendarNavigator = () => (
    <Stack.Navigator
    initialRouteName='Calendario'>
        <Stack.Screen name='Calendario' component={UserCalendarView}></Stack.Screen>
        <Stack.Screen name='Cambiar fecha' component={CustomCalendarView}></Stack.Screen>
    </Stack.Navigator>

);

export default UserCalendarNavigator;