
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { ClassType} from "../../models";
import { baseListGQLRequest, baseGQLRequest } from './goulapi.utils';

// Class Types
export async function listClassTypes():  Promise<any[]>{
    return baseListGQLRequest({}, queries.listClassTypes);
}
export function createClassType(newClassType: ClassType) {
    return baseGQLRequest({ input: newClassType }, mutations.createClassType);
}

export function updateClassType(classType) {
    return baseGQLRequest({ input: classType }, mutations.updateClassType);
}

export function deleteClassType(classTypeID: string) {
    return baseGQLRequest({ input: { id: classTypeID } }, mutations.deleteClassType);
}