import React, { useEffect, useState } from 'react';
import { Text, StyleSheet, TouchableOpacity, View } from 'react-native';
import { Class, User } from '../../../../models';
import { ClassState, UserState } from '../../../../types';
import Button from '@ant-design/react-native/lib/button';
import { admin_createUserCanceledClass, removeUserSessionJoined } from '../../../../services/goulapi/goulapi.user';
import YesNoModal from '../../../../modals/YesNoModal';
import { Tooltip } from 'react-native-elements';
import { admin_cancelClassDay } from '../../../../services/goulapi/goulapi.service';

interface AdminClassProps {
    selectedClass: Class;
    users: User[];
    setToReload;
}

const CalendarClass: React.FC<AdminClassProps> = ({ selectedClass, users, setToReload }) => {
    const [isExpanded, setExpanded] = useState(false);
    const [calendarClassView, setCalendarClassView] = useState<any>(null);
    const buttonBorderColor = selectedClass.classType.color || 'red';

    const handlePress = () => {
        setExpanded(!isExpanded);
    };

    function searchUserName(selectedClass) {
        return selectedClass.users.map((userToFind, index) => {
            const foundUser = users.find((user) => user.userName === userToFind.userId);
            return (foundUser ?
        <View>
            <Tooltip 
                popover={
                    <View>
                        <Text style={styles.itemInfo}>{"email: " + foundUser?.email}</Text>
                        <Text style={styles.itemInfo}>{"Telf.: " + foundUser?.telf}</Text>
                    </View>
                }
                width={300}
                height={70}
                backgroundColor={'#fff'}
                withOverlay={false}
                withPointer={false}
            >
                <Text
                    key={userToFind.id}
                    style={[
                        styles.itemUser,
                        {
                            color:
                                userToFind.status === null
                                    ? 'black'
                                    : userToFind.status === UserState.RESCHEDULED
                                        ? 'lightblue'
                                        : userToFind.status >= UserState.CANCELED_RESCHEDULED
                                            ? '#F9A97C' // Adjust color as needed for strikethrough
                                            : 'black', // Default color
                            textDecorationLine: userToFind.status >= UserState.CANCELED_RESCHEDULED ? 'line-through' : 'none',
                            paddingLeft: userToFind.status >= UserState.CANCELED_RESCHEDULED ? 26 : 0,
                        },
                    ]}
                >
                    {userToFind.status < UserState.CANCELED_RESCHEDULED ? 
                        <YesNoModal
                            buttonText="X"
                            buttonStyle={styles.buttonDelete}
                            buttonTextStyle={styles.buttonDeleteText}
                            question={"¿Estas seguro que quieres borrar al usuario " + foundUser?.name + " de la clase?"}
                            confirmationMessage="Esta accion no se puede deshacer. ¿Deseas continuar?"
                            handleYes={() => handleDeleteUser(selectedClass, userToFind)}
                            handleNo={()=>{}}
                        />:
                            null
                    }
                    {foundUser?.name}
                </Text>
            </Tooltip>
        </View> : null)
        });
    }

    function handleDeleteUser(selectedClass, user) {
        if(user.status == UserState.REGULAR){
            admin_createUserCanceledClass(user.userId, selectedClass.id, selectedClass.date)
        } else if(user.status == UserState.RESCHEDULED){
            removeUserSessionJoined(user.userJoinedClassID);    
        }
        setToReload(true);
    }

    function handleCancelClass(selectedClass) {
        admin_cancelClassDay(selectedClass.id, selectedClass.date);
        setToReload(true);
    }

    useEffect(() => {
        setCalendarClassView(<>
            <TouchableOpacity
                style={[styles.button, { borderColor: buttonBorderColor }]}
                onPress={handlePress}
            >
                {selectedClass.state == ClassState.AVAILABLE ?
                <>
                    <View>                    
                        <YesNoModal
                            buttonText="Cancelar"
                            buttonStyle={styles.buttonDelete}
                            buttonTextStyle={styles.buttonDeleteText}
                            question={"¿Estas seguro que quieres la clase " + selectedClass.classType + " de las " +  selectedClass.time + "?"}
                            confirmationMessage="Se eliminarán todas las sessiones reagendades de esta clase, esta accion no se puede deshacer. ¿Deseas continuar?"
                            handleYes={() => handleCancelClass(selectedClass)}
                            handleNo={()=>{}}
                        />
                    </View>
                    <Text style={styles.itemType}>
                        {selectedClass.time} - {selectedClass.classType}
                    </Text>
                    <Text style={styles.itemTime}>({selectedClass.duration}min)</Text>
                    <Text style={styles.itemTime}>{selectedClass.users.filter((user) => user.status < UserState.CANCELED_RESCHEDULED).length} / {selectedClass.maxAssist}</Text>
                    {isExpanded && (
                        <View style={styles.divider} />
                    )}
                    {isExpanded && Array.isArray(selectedClass.users) && selectedClass.users.length > 0 && (
                        <>
                            <View style={styles.divider} />
                            <Text style={styles.itemTime}>{"______________________"}</Text>
                            {searchUserName(selectedClass)}
                        </>
                    )}
                </>
                :
                <>
                <Text style={[styles.itemType, {textDecorationLine: 'line-through'}]}>
                        {selectedClass.time} - {selectedClass.classType}
                    </Text>
                    <Text style={styles.itemTime}>({selectedClass.duration}min)</Text>
                    <Text style={styles.itemTime}> 0/ {selectedClass.maxAssist}</Text></>
                }

            </TouchableOpacity>
        </>);
      }, [selectedClass, users, isExpanded]);

    return (
        <div>
          {calendarClassView}
        </div>
    );
};

export default CalendarClass;

const styles = StyleSheet.create({
    button: {
        margin: 10,
        borderRadius: 10,
        borderWidth: 1,
        padding: 10,
    },
    itemType: {
        color: 'black',
        fontSize: 16,
        textAlign: 'center',
    },
    itemTime: {
        color: 'lightgray',
        fontSize: 14,
        textAlign: 'center',
            },
    itemUser: {
        color: 'lightgray',
        fontSize: 14,
        textAlign: 'justify',
            },
    itemInfo: {
        color: 'black',
        fontSize: 14,
        textAlign: 'justify',
            },
    divider: {
        height: 1,
        backgroundColor: 'gray',
        marginVertical: 3,
    },
    buttonText: {
        letterSpacing: 1,
        fontSize: 13,
        textAlign: 'center',
    },
    buttonDelete: {
        padding: 10,
        borderRadius: 5,
        borderWidth: 0, // This removes the border
    },
    buttonDeleteText: {
        color: 'red',
        textAlign: 'center',
    },
});
