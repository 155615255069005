import React from 'react'
import { View } from 'react-native'
import Constants from 'expo-constants'
import AppBar from './components/AppBar'
import { LoadingProvider } from './components/LoadingContext';



const Main = () => {
  return (
      <View style={{marginTop: Constants.statusBarHeight, flexGrow: 1}}>
        <LoadingProvider>
          <AppBar />
        </LoadingProvider>
      </View>
  )
}

export default Main
