import { Class } from '../models';

export const formatAllClases = (classList: Class[]) => {
    var classDict = {};
    classList.forEach((element) => classDict[element.id] = element);
    return classDict;
}

export const sortClassesByDay = (classList: Class[]) => {
    var classDict = {1: [], 2: [], 3: [], 4: [], 5: []}
    classList.forEach((element) => classDict[element.dayOfWeek].push(element));
    return classDict;
}