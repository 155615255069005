import React, { useState } from 'react';
import { View, Text, Modal, StyleSheet, Pressable } from 'react-native';

const YesNoModal = ({ buttonText, buttonStyle, buttonTextStyle, question, confirmationMessage, handleYes, handleNo }) => {
  const [modalVisible, setModalVisible] = useState(false);


  return (
    <View>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <View style={styles.confirmationModal}>
            <Text style={styles.confirmationText}>{question}</Text>
            <Text style={styles.confirmationText}>{confirmationMessage}</Text>
            <View style={{ flexDirection: 'row'}}>
              <Pressable style={styles.yesButton} onPress={() => { handleYes(); setModalVisible(false); }}>
                <Text style={styles.buttonText}>Si</Text>
              </Pressable>
              <Pressable style={styles.noButton} onPress={() => { handleNo(); setModalVisible(false); }}>
                <Text style={styles.buttonText}>No</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </Modal>

      <Pressable
        style={buttonStyle}
        onPress={() => setModalVisible(true)}
      >
        <Text
        style={buttonTextStyle}>
            {buttonText}</Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
    yesButton: {
      backgroundColor: '#7CFC00', // Apple green color
      padding: 10,
      marginRight: 5
    },
    noButton: {
      backgroundColor: 'red',
      padding: 10,
      marginLeft: 5
    },
    buttonText: {
        color: 'white',
        textAlign: 'center',
    },
    confirmationText: {
        color: 'white',
    }, 
    confirmationModal: {
       justifyContent: 'center',
       alignItems: 'center',
       backgroundColor: 'rgba(0, 0, 0, 0.5)',
       padding: 20, 
    }
  });

export default YesNoModal;