import React, { useState } from 'react';
import { Class } from '../../../../../models';
import { StyleSheet, TouchableOpacity, View, Text, Button } from 'react-native';
import { Picker } from '@react-native-picker/picker';

interface ClassAdderProps {
    onSave: (classId: string) => void;
    classList: Class[];
}

const ClassAdder: React.FC<ClassAdderProps> = ({ onSave, classList }) => {
    const [selectedDayOfTheWeek, setSelectedDayOfTheWeek] = useState<string | null>(null);
    const [selectedClassType, setSelectedClassType] = useState<string | null>(null);
    const [selectedClass, setSelectedClass] = useState<string | null>(null);
    var dayOfWeek = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes"];

    return (
        <>
            <Picker
                style={styles.picker}
                selectedValue={selectedDayOfTheWeek}
                onValueChange={(itemValue: string) => {
                    setSelectedDayOfTheWeek(itemValue);
                }}
            >
                <Picker.Item key={0} label={''} value={null} />
                {[...new Set(classList.sort((a, b) => a.dayOfWeek - b.dayOfWeek).map((classSel) => classSel.dayOfWeek))]
                    .map((dayOfWeekValue, key) => (
                        <Picker.Item
                            key={key + 1}
                            label={dayOfWeek[dayOfWeekValue - 1]}
                            value={dayOfWeekValue.toString()}
                        />
                    ))}
            </Picker>
            <Picker
                style={styles.picker}
                enabled={selectedDayOfTheWeek ? true : false}
                selectedValue={selectedClassType}
                onValueChange={(itemValue: string) => {
                    setSelectedClassType(itemValue);
                }}
            >
                <Picker.Item key={0} label={''} value={null} />
                {[...new Set(
                    classList
                        .filter((cls) => cls.dayOfWeek.toString() === selectedDayOfTheWeek)
                        .sort((a, b) => a.classType.name.localeCompare(b.classType.name))
                        .map((classSel) => classSel.classType.name)
                )].map((classTypeName, key) => (
                    <Picker.Item key={key} label={classTypeName} value={classTypeName} />
                ))}
            </Picker>
            <Picker
                style={styles.picker}
                enabled={selectedDayOfTheWeek && selectedClassType ? true : false}
                selectedValue={selectedClass}
                onValueChange={(itemValue: string) => {
                    setSelectedClass(itemValue);
                }}
            >
                <Picker.Item key={0} label={''} value={null} />
                {classList
                    .filter((cls) => cls.dayOfWeek.toString() === selectedDayOfTheWeek)
                    .filter((cls) => cls.classType.name === selectedClassType)
                    .sort((a, b) => a.time.localeCompare(b.time))
                    .map((classSel, key) => (
                        <Picker.Item key={key} enabled = {classSel.users.items.length < classSel.maxAssist} label={classSel.time + " (" + classSel.users.items.length + "/" + classSel.maxAssist + ")"} value={classSel.id} />
                    ))}
            </Picker>
            <TouchableOpacity
                onPress={() => onSave(selectedClass)}
                disabled={selectedClass && selectedDayOfTheWeek && selectedClassType ? false : true}
                style={[
                    styles.addButton,
                    { backgroundColor: selectedClass && selectedDayOfTheWeek && selectedClassType ? '#95EC91' : '#D3D3D3' },
                ]}
            >
                <Text style={styles.addButtonText}>{'+'}</Text>
            </TouchableOpacity>
        </>
    );
};

export default ClassAdder;

const styles = StyleSheet.create({
    picker: {
        height: 40,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 5,
        padding: 10,
        marginBottom: 10,
        marginRight: 15,
        minWidth: '25%', // Minimum width of 1/4 the container
    },
    addButton: {
        marginBottom: 10,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        width: '3%', // You can adjust the width as needed
        height: 40, // You can adjust the height as needed
    },
    addButtonText: {
        color: 'white', // White text color
        fontSize: 20, // You can adjust the font size as needed
    },
});
