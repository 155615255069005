import React, {useEffect} from 'react'
import Main from './src/Main.jsx'
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react-native';
import { Amplify } from 'aws-amplify';
import awsExports from './src/aws-exports';
Amplify.configure(awsExports);

function App () {

  return (
    <Authenticator.Provider>
      <Authenticator
        components={{
          SignIn: (props) => (
            <Authenticator.SignIn {...props} hideSignUp />
          ),
        }}
      >
      <Main />
      </Authenticator>
    </Authenticator.Provider>
  );
}

export default App;