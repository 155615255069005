import { API, graphqlOperation } from 'aws-amplify';

export async function baseListGQLRequest(variables: any, query: string): Promise<any[]> {
    let items: any[] = [];
    variables.nextToken = null;
    const queryPart = query.split(' ')[3].split('(')[0];
    const propertyName = queryPart.charAt(0).toLowerCase() + queryPart.slice(1);

    try {
        const startTime = Date.now();
        var counter = 0;
        do {
            const response = await API.graphql({
                query: query,
                variables: variables,
                authMode: 'AMAZON_COGNITO_USER_POOLS'
            });

            items.push(...response.data[propertyName]?.items || []);
            variables.nextToken = response.data[propertyName]?.nextToken;
            ++counter;
        } while (variables.nextToken);

        const endTime = Date.now();
        const timeTaken = endTime - startTime;
        console.log(`Query: ${query.split('(')[0].trim()}, Time taken: ${timeTaken}ms (${counter} calls)`);

    } catch (error) {
        console.log('Error retrieving ' + propertyName + ': ', error);
    }
    return items;
}

export async function baseGQLRequest(variables: any, query: string): Promise<any> {
    const queryPart = query.split(' ')[3].split('(')[0];
    const propertyName = queryPart.charAt(0).toLowerCase() + queryPart.slice(1);
    let item: any = {};

    try {
        const startTime = Date.now();
        const response = await API.graphql({
            query: query,
            variables: variables,
            authMode: 'AMAZON_COGNITO_USER_POOLS',
        });
        const endTime = Date.now();
        const timeTaken = endTime - startTime;
        console.log(`Query: ${query.split('(')[0].trim()}, Time taken: ${timeTaken}ms`);

        item = response.data[propertyName];
    } catch (error) {
        console.log('Error retrieving ' + propertyName + ': ', error);
    }
    return item;
}

export function getWeekFilterAndNotCancelled(weekFrom: number, weekTo: number) {
    var variables = {};
    
    if (weekFrom != 0 && weekTo != 0) {
        variables = {
            filter: {
                isCancelled: { ne: true },
                and: { date: { between: [weekFrom, weekTo] } }
            }
        }
    }

    return variables;
}