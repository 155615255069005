import React, { useEffect, useState } from 'react';
import { View, TextInput, Pressable, FlatList, Modal, Text, StyleSheet } from 'react-native';

import { API, Auth } from 'aws-amplify';
import * as queries from '../../../graphql/queries';
import * as mutations from '../../../graphql/mutations';
import { createClassType, deleteClassType, listClassTypes, updateClassType } from '../../../services/goulapi/goulapi.classtype';
import { listOfUsers, resendUserEmail } from '../../../services/cognito/cognito.service';

const ReSendEmail = () => {
  const [input, setInput] = useState({
    name: '',
    email: ''
  });
  const [modalVisibleCreate, setModalVisibleCreate] = useState(false);
  const [token, setToken] = useState('');

  const [classTypes, setClassTypes] = useState([]);

  const fetchClassTypes = async () => {
    try {
      setToken((await Auth.currentSession()).getAccessToken().getJwtToken());
    } catch (error) {
      console.log('Error retrieving classTypes:', error);
    }
  };

  useEffect(() => {
    fetchClassTypes();
  }, []);

  const handleSubmit = async () => {
    const response = resendUserEmail(token, input.name, input.email);
    fetchClassTypes();
    setInput({ name: '', email: ''});
    setModalVisibleCreate(false);
  };

  const findAttinUser = (user, val) => {
    const filter = Object.entries(user.Attributes).find(([key, att]) => att?.Name === val);
    if(filter)
    {
        return filter[1].Value;
    }
    return '';
}

const reSendAll = async () => {
  const allUsers = await listOfUsers(token);
  for (const user of allUsers) {
    if (user.UserStatus === 'FORCE_CHANGE_PASSWORD') {
      const response = await resendUserEmail(token, findAttinUser(user, 'name'), findAttinUser(user, 'email'));
      await new Promise(resolve => setTimeout(resolve, 1000)); // wait for 1s
      console.log(response);
      if(response === null)
      {
        break;
      }
    }
  }
  fetchClassTypes();
  setModalVisibleCreate(false);
};

  const renderItem = ({ item }) => (
    <View style={styles.row}>
            <View style={[styles.column, { flex: 0.5 }]}>
        <Text style={styles.field}>{item.name}</Text>
      </View>
      <View style={[styles.column, { flex: 0.5 }]}>
        <Text style={styles.field}>{item.email}</Text>
      </View>
    </View>
  );

  return (
    <View style={styles.container}>
      <Pressable onPress={() => setModalVisibleCreate(true)}>
        <Text style={styles.button}>re-send email to user</Text>
      </Pressable>
      <Pressable onPress={() => reSendAll()}>
        <Text style={styles.button}>re-send email to all</Text>
      </Pressable>
      <Modal visible={modalVisibleCreate} animationType="slide">
        <View style={styles.modalContent}>
        <TextInput
            placeholder="Name"
            value={input.name}
            onChangeText={(Text) => setInput({ ...input, name: Text })}
          />
          <TextInput
            placeholder="email"
            value={input.email}
            onChangeText={(Text) => setInput({ ...input, email: Text })}
          />
          <Pressable onPress={handleSubmit}>
            <Text style={styles.button}>Submit</Text>
          </Pressable>
          <Pressable onPress={() => setModalVisibleCreate(false)}>
            <Text style={styles.button}>Cancel</Text>
          </Pressable>
        </View>
      </Modal>
      <FlatList
        data={classTypes}
        keyExtractor={(item) => item.id}
        renderItem={renderItem}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  modalContent: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 20,
    paddingTop: 100, // Add more padding to the top side
    borderRadius: 10,
    elevation: 5,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  column: {
    flex: 1,
    marginHorizontal: 5,
  },
  field: {
    fontSize: 16,
    marginBottom: 5,
  },
  button: {
    fontSize: 16,
    marginTop: 5,
    color: 'blue',
  },
});

export default ReSendEmail