import React from 'react';
import { Text, StyleSheet, View, ScrollView, SafeAreaView } from 'react-native';
import { Table, TableWrapper, Row, Cols, Col } from 'react-native-table-component';
import { generateTimeArray } from '../../../../../utils/dateUtils'
import ScheduleColumn from './ScheduleColumn';
//Antd
import ActivityIndicator from '@ant-design/react-native/lib/activity-indicator';
import TimeColumn from './TimeColumn';


interface ScheduleProps {
    classList: {};
    allUsers:  {};
    setToReload;
}

const Schedule: React.FC<ScheduleProps> = ({ classList, allUsers, setToReload}) => {
    var dayOfWeek = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes"];

    function formatSchedulerColumns() {
        var dataTableFormatted = [];

        dataTableFormatted.push([<TimeColumn />])

        dayOfWeek.map((day, index) => (
            dataTableFormatted.push([
                <ScheduleColumn classList={classList[index+1]} allUsers={allUsers} isClassSelectable={true} setToReload={setToReload} />
            ])
        ));
        return dataTableFormatted;
    }

    return (
            <>
                <View style={styles.container}>
                    <Table style={styles.table}>
                        <Row data={[""].concat(dayOfWeek)} style={styles.header} textStyle={styles.headerText} />
                        <Cols data={formatSchedulerColumns()} />
                    </Table>
                </View >
            </>);
};

export default Schedule;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column"
      },
    table: {
        width: '100%',
        minWidth: 700,
        maxWidth: 'auto'
    },
    header: {
        backgroundColor: '#FEF3F3',
        height: 40,
        borderWidth: 0
      },
      headerText: {
        fontSize: 15,
        color: '#FF7777',
        fontWeight: 'bold',
        textAlign: 'center',
        marginLeft: 10,
        borderBottomColor: '#CE9595'
      },

});