import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Table, Row,  } from 'react-native-table-component';
import { generateTimeArray } from '../../../../../utils/dateUtils'
//Antd

interface TimeColumnProps {
}

const TimeColumn: React.FC<TimeColumnProps> = () => {

    function getTimeColumn() {
        var timeTable = generateTimeArray('8:00', '22:00', 45);

        return (
            timeTable.map((startTimeStr, index) => (
                <View style={{ borderBottomWidth: 1, borderRightWidth: 1, borderColor: '#C1C0B9'}}><Row
                    data={[startTimeStr]}
                    style={styles.row}
                    textStyle={styles.text}
                /></View>
            ))
        );
    }

    return (
        <>
            <View style={styles.container}>
                <Table borderStyle={{ borderWidth: 0, borderColor: '#C1C0B9' }}>
                    {getTimeColumn()}
                </Table>
            </View>
        </>)
};

export default TimeColumn;

const styles = StyleSheet.create({
    container: { flex: 1, paddingTop: 10, backgroundColor: '#fff' },
    head: { height: 40, backgroundColor: '#808B97' },
    text: { margin: 6, color: 'black' },
    row: { flexDirection: 'row', backgroundColor: '#fff', minHeight: 52 },
});
