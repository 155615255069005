import { Class, User } from "./models";

//Normal user
export const UserState = {
    REGULAR: 0,
    RESCHEDULED: 1,
    CANCELED_RESCHEDULED: 2,
    CANCELED_NON_RESCHEDULED: 3,
    CANCELED: 4
  };

  export const ClassState = {
    AVAILABLE: 0,
    CANCELED: 1,
    CANCELED_NO_RESCHEDULE: 2,
  };

export type UserClassSession = Class & {
    state: typeof UserState, 
    numOfAssist: number,
    date: string 
    canceledClassID: string
}

export type ClassSession = Class & {
    state: typeof ClassState,
    numOfAssist: number,
    date: string 
}

export type UserExtended = User & {
    email: string,
    name: string,
    telf: string,
    status: string 
}