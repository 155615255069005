import React, { useEffect } from 'react';
import { Class, ClassUsers } from '../../../../../models';
import { StyleSheet, TouchableOpacity, View, Text } from 'react-native';
import { Ionicons } from '@expo/vector-icons'; // Import Ionicons

interface ClassTagProps {
    onDelete: (classID: string) => void;
    tagClass: ClassUsers;
    key: any;
    isLoading: boolean;
    isCloseable: boolean;
};

const ClassTag: React.FC<ClassTagProps & { classKey: string }> = ({ onDelete, classKey, tagClass, isCloseable }) => {
    const dayOfWeek = ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes"];

    function formatClassNameForTag(tagClass: Class) {
        return tagClass.classType.name + " - " + dayOfWeek[tagClass.dayOfWeek - 1] + " [" + tagClass.time + "]";
    }

    return (
        <View key={classKey} style={styles.tag}>
            <Text>{formatClassNameForTag(tagClass.class)}</Text>
            {isCloseable ? (
                <TouchableOpacity onPress={() => onDelete(tagClass.id)}>
                    <Text style={styles.deleteButton}>
                        <Ionicons name="close-sharp" />
                    </Text>
                </TouchableOpacity>
            ) : null}
        </View>
    );
};

export default ClassTag;


const styles = StyleSheet.create({
    label: {
        fontWeight: 'bold',
        marginTop: 10,
    },
    tag: {
        backgroundColor: '#e0e0e0',
        padding: 5,
        margin: 5,
        borderRadius: 5,
        flexDirection: 'row',
        alignItems: 'center',
    },
    deleteButton: {
        marginLeft: 5,
        color: 'red',
    },
});
