/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createClassType = /* GraphQL */ `
  mutation CreateClassType(
    $input: CreateClassTypeInput!
    $condition: ModelClassTypeConditionInput
  ) {
    createClassType(input: $input, condition: $condition) {
      id
      name
      description
      color
      classes {
        items {
          id
          classTypeID
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClassType = /* GraphQL */ `
  mutation UpdateClassType(
    $input: UpdateClassTypeInput!
    $condition: ModelClassTypeConditionInput
  ) {
    updateClassType(input: $input, condition: $condition) {
      id
      name
      description
      color
      classes {
        items {
          id
          classTypeID
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClassType = /* GraphQL */ `
  mutation DeleteClassType(
    $input: DeleteClassTypeInput!
    $condition: ModelClassTypeConditionInput
  ) {
    deleteClassType(input: $input, condition: $condition) {
      id
      name
      description
      color
      classes {
        items {
          id
          classTypeID
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClass = /* GraphQL */ `
  mutation CreateClass(
    $input: CreateClassInput!
    $condition: ModelClassConditionInput
  ) {
    createClass(input: $input, condition: $condition) {
      id
      classTypeID
      classType {
        id
        name
        description
        color
        classes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      description
      time
      duration
      dayOfWeek
      maxAssist
      users {
        items {
          id
          classId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      canceledClasses {
        items {
          id
          date
          description
          classID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClass = /* GraphQL */ `
  mutation UpdateClass(
    $input: UpdateClassInput!
    $condition: ModelClassConditionInput
  ) {
    updateClass(input: $input, condition: $condition) {
      id
      classTypeID
      classType {
        id
        name
        description
        color
        classes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      description
      time
      duration
      dayOfWeek
      maxAssist
      users {
        items {
          id
          classId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      canceledClasses {
        items {
          id
          date
          description
          classID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClass = /* GraphQL */ `
  mutation DeleteClass(
    $input: DeleteClassInput!
    $condition: ModelClassConditionInput
  ) {
    deleteClass(input: $input, condition: $condition) {
      id
      classTypeID
      classType {
        id
        name
        description
        color
        classes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      description
      time
      duration
      dayOfWeek
      maxAssist
      users {
        items {
          id
          classId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      canceledClasses {
        items {
          id
          date
          description
          classID
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isDeleted
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      classes {
        items {
          id
          classId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      canceledClasses {
        items {
          id
          userID
          classID
          date
          owner
          isCancelled
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      joinedClasses {
        items {
          id
          userCanceledClassID
          userID
          classID
          date
          owner
          isCancelled
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      classes {
        items {
          id
          classId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      canceledClasses {
        items {
          id
          userID
          classID
          date
          owner
          isCancelled
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      joinedClasses {
        items {
          id
          userCanceledClassID
          userID
          classID
          date
          owner
          isCancelled
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      classes {
        items {
          id
          classId
          userId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      canceledClasses {
        items {
          id
          userID
          classID
          date
          owner
          isCancelled
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      joinedClasses {
        items {
          id
          userCanceledClassID
          userID
          classID
          date
          owner
          isCancelled
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserCanceledClass = /* GraphQL */ `
  mutation CreateUserCanceledClass(
    $input: CreateUserCanceledClassInput!
    $condition: ModelUserCanceledClassConditionInput
  ) {
    createUserCanceledClass(input: $input, condition: $condition) {
      id
      userJoinedClass {
        items {
          id
          userCanceledClassID
          userID
          classID
          date
          owner
          isCancelled
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      userID
      user {
        id
        classes {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        joinedClasses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      classID
      class {
        id
        classTypeID
        classType {
          id
          name
          description
          color
          createdAt
          updatedAt
          __typename
        }
        description
        time
        duration
        dayOfWeek
        maxAssist
        users {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      date
      owner
      isCancelled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserCanceledClass = /* GraphQL */ `
  mutation UpdateUserCanceledClass(
    $input: UpdateUserCanceledClassInput!
    $condition: ModelUserCanceledClassConditionInput
  ) {
    updateUserCanceledClass(input: $input, condition: $condition) {
      id
      userJoinedClass {
        items {
          id
          userCanceledClassID
          userID
          classID
          date
          owner
          isCancelled
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      userID
      user {
        id
        classes {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        joinedClasses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      classID
      class {
        id
        classTypeID
        classType {
          id
          name
          description
          color
          createdAt
          updatedAt
          __typename
        }
        description
        time
        duration
        dayOfWeek
        maxAssist
        users {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      date
      owner
      isCancelled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserCanceledClass = /* GraphQL */ `
  mutation DeleteUserCanceledClass(
    $input: DeleteUserCanceledClassInput!
    $condition: ModelUserCanceledClassConditionInput
  ) {
    deleteUserCanceledClass(input: $input, condition: $condition) {
      id
      userJoinedClass {
        items {
          id
          userCanceledClassID
          userID
          classID
          date
          owner
          isCancelled
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      userID
      user {
        id
        classes {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        joinedClasses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      classID
      class {
        id
        classTypeID
        classType {
          id
          name
          description
          color
          createdAt
          updatedAt
          __typename
        }
        description
        time
        duration
        dayOfWeek
        maxAssist
        users {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      date
      owner
      isCancelled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUserJoinedClass = /* GraphQL */ `
  mutation CreateUserJoinedClass(
    $input: CreateUserJoinedClassInput!
    $condition: ModelUserJoinedClassConditionInput
  ) {
    createUserJoinedClass(input: $input, condition: $condition) {
      id
      userCanceledClassID
      userCanceledClass {
        id
        userJoinedClass {
          nextToken
          __typename
        }
        userID
        user {
          id
          createdAt
          updatedAt
          __typename
        }
        classID
        class {
          id
          classTypeID
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        date
        owner
        isCancelled
        createdAt
        updatedAt
        __typename
      }
      userID
      user {
        id
        classes {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        joinedClasses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      classID
      class {
        id
        classTypeID
        classType {
          id
          name
          description
          color
          createdAt
          updatedAt
          __typename
        }
        description
        time
        duration
        dayOfWeek
        maxAssist
        users {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      date
      owner
      isCancelled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserJoinedClass = /* GraphQL */ `
  mutation UpdateUserJoinedClass(
    $input: UpdateUserJoinedClassInput!
    $condition: ModelUserJoinedClassConditionInput
  ) {
    updateUserJoinedClass(input: $input, condition: $condition) {
      id
      userCanceledClassID
      userCanceledClass {
        id
        userJoinedClass {
          nextToken
          __typename
        }
        userID
        user {
          id
          createdAt
          updatedAt
          __typename
        }
        classID
        class {
          id
          classTypeID
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        date
        owner
        isCancelled
        createdAt
        updatedAt
        __typename
      }
      userID
      user {
        id
        classes {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        joinedClasses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      classID
      class {
        id
        classTypeID
        classType {
          id
          name
          description
          color
          createdAt
          updatedAt
          __typename
        }
        description
        time
        duration
        dayOfWeek
        maxAssist
        users {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      date
      owner
      isCancelled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserJoinedClass = /* GraphQL */ `
  mutation DeleteUserJoinedClass(
    $input: DeleteUserJoinedClassInput!
    $condition: ModelUserJoinedClassConditionInput
  ) {
    deleteUserJoinedClass(input: $input, condition: $condition) {
      id
      userCanceledClassID
      userCanceledClass {
        id
        userJoinedClass {
          nextToken
          __typename
        }
        userID
        user {
          id
          createdAt
          updatedAt
          __typename
        }
        classID
        class {
          id
          classTypeID
          description
          time
          duration
          dayOfWeek
          maxAssist
          isDeleted
          createdAt
          updatedAt
          __typename
        }
        date
        owner
        isCancelled
        createdAt
        updatedAt
        __typename
      }
      userID
      user {
        id
        classes {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        joinedClasses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      classID
      class {
        id
        classTypeID
        classType {
          id
          name
          description
          color
          createdAt
          updatedAt
          __typename
        }
        description
        time
        duration
        dayOfWeek
        maxAssist
        users {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      date
      owner
      isCancelled
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCanceledClass = /* GraphQL */ `
  mutation CreateCanceledClass(
    $input: CreateCanceledClassInput!
    $condition: ModelCanceledClassConditionInput
  ) {
    createCanceledClass(input: $input, condition: $condition) {
      id
      date
      description
      classID
      class {
        id
        classTypeID
        classType {
          id
          name
          description
          color
          createdAt
          updatedAt
          __typename
        }
        description
        time
        duration
        dayOfWeek
        maxAssist
        users {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCanceledClass = /* GraphQL */ `
  mutation UpdateCanceledClass(
    $input: UpdateCanceledClassInput!
    $condition: ModelCanceledClassConditionInput
  ) {
    updateCanceledClass(input: $input, condition: $condition) {
      id
      date
      description
      classID
      class {
        id
        classTypeID
        classType {
          id
          name
          description
          color
          createdAt
          updatedAt
          __typename
        }
        description
        time
        duration
        dayOfWeek
        maxAssist
        users {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCanceledClass = /* GraphQL */ `
  mutation DeleteCanceledClass(
    $input: DeleteCanceledClassInput!
    $condition: ModelCanceledClassConditionInput
  ) {
    deleteCanceledClass(input: $input, condition: $condition) {
      id
      date
      description
      classID
      class {
        id
        classTypeID
        classType {
          id
          name
          description
          color
          createdAt
          updatedAt
          __typename
        }
        description
        time
        duration
        dayOfWeek
        maxAssist
        users {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCancelledDay = /* GraphQL */ `
  mutation CreateCancelledDay(
    $input: CreateCancelledDayInput!
    $condition: ModelCancelledDayConditionInput
  ) {
    createCancelledDay(input: $input, condition: $condition) {
      id
      date
      description
      unavailableDay
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCancelledDay = /* GraphQL */ `
  mutation UpdateCancelledDay(
    $input: UpdateCancelledDayInput!
    $condition: ModelCancelledDayConditionInput
  ) {
    updateCancelledDay(input: $input, condition: $condition) {
      id
      date
      description
      unavailableDay
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCancelledDay = /* GraphQL */ `
  mutation DeleteCancelledDay(
    $input: DeleteCancelledDayInput!
    $condition: ModelCancelledDayConditionInput
  ) {
    deleteCancelledDay(input: $input, condition: $condition) {
      id
      date
      description
      unavailableDay
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createClassUsers = /* GraphQL */ `
  mutation CreateClassUsers(
    $input: CreateClassUsersInput!
    $condition: ModelClassUsersConditionInput
  ) {
    createClassUsers(input: $input, condition: $condition) {
      id
      classId
      userId
      class {
        id
        classTypeID
        classType {
          id
          name
          description
          color
          createdAt
          updatedAt
          __typename
        }
        description
        time
        duration
        dayOfWeek
        maxAssist
        users {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        classes {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        joinedClasses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateClassUsers = /* GraphQL */ `
  mutation UpdateClassUsers(
    $input: UpdateClassUsersInput!
    $condition: ModelClassUsersConditionInput
  ) {
    updateClassUsers(input: $input, condition: $condition) {
      id
      classId
      userId
      class {
        id
        classTypeID
        classType {
          id
          name
          description
          color
          createdAt
          updatedAt
          __typename
        }
        description
        time
        duration
        dayOfWeek
        maxAssist
        users {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        classes {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        joinedClasses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteClassUsers = /* GraphQL */ `
  mutation DeleteClassUsers(
    $input: DeleteClassUsersInput!
    $condition: ModelClassUsersConditionInput
  ) {
    deleteClassUsers(input: $input, condition: $condition) {
      id
      classId
      userId
      class {
        id
        classTypeID
        classType {
          id
          name
          description
          color
          createdAt
          updatedAt
          __typename
        }
        description
        time
        duration
        dayOfWeek
        maxAssist
        users {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        isDeleted
        createdAt
        updatedAt
        __typename
      }
      user {
        id
        classes {
          nextToken
          __typename
        }
        canceledClasses {
          nextToken
          __typename
        }
        joinedClasses {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
