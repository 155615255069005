import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import View from '@ant-design/react-native/lib/view';
import Schedule from './component/Schedule/Schedule';
import AddClassButton from './component/AddClassButton';
import { Class } from '../../../models';
import { addClass, getAvailableClasses } from '../../../services/goulapi/goulapi.class';
import { listClassTypes } from '../../../services/goulapi/goulapi.classtype';
import { sortClassesByDay } from '../../../utils/classFormatters';
import { LoadingContext } from '../../../components/LoadingContext';
import { Auth } from 'aws-amplify';
import { listOfUsers } from '../../../services/cognito/cognito.service';


function AdminClassSchedule() {
    var allUsers;
    const [classList, setClassList] = useState({});
    const [classTypeList, setClassTypeList] = useState([]);
    const [toReload, setToReload] = useState(true);
    const { isLoading, setIsLoading } = useContext(LoadingContext);


    useEffect(() => {
        async function setClasses() {
            setClassList(sortClassesByDay(await getAvailableClasses()));
            allUsers = await listOfUsers((await Auth.currentSession()).getAccessToken().getJwtToken());
            setClassTypeList(await listClassTypes());
            setToReload(false);
            setIsLoading(false);
        }
        if (toReload) {
            setIsLoading(true);
            setClasses();
        }
    }, [toReload]);

    return (<>
        <SafeAreaView style={styles.container}>
            {toReload ? null :
                <>
                    <View style={styles.buttonView}>
                        <AddClassButton classTypes={classTypeList} buttonText='+ Añadir' onSave={async (session: Class) => { await addClass(session); setToReload(true) }}></AddClassButton>
                    </View>
                    <View style={styles.scheduleView}>
                        <Schedule classList={classList} allUsers={allUsers} setToReload={setToReload}></Schedule>
                    </View>
                </>
            }
        </SafeAreaView>

    </>);
}

export default AdminClassSchedule;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#FFF',
        padding: 20,
        margin: 5,
    },
    scheduleView: {
        marginTop: 10,
        marginBottom: 2,
        margin: 10,
    },
    buttonView: {
        marginTop: 10,
        marginBottom: 2,
        margin: 10,
        alignItems: 'flex-end',
    },

});
